import React, { createContext, useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import useStickyState from '@hooks/useStickyState'
import IUser from '@interfaces/IUser'
import * as $Auth from '@services/Auth'
import * as $Sanctum from '@services/Sanctum'
import { Outlet, useNavigate } from 'react-router-dom'

type AuthContextProps = {
  isLoading: boolean
  isAuthenticated: boolean
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
  user: IUser|null
  setUser: React.Dispatch<React.SetStateAction<IUser|null>>
  token: string|null
  setToken: React.Dispatch<React.SetStateAction<string|null>>
  logout: () => void
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps)

export const AuthProvider: React.FC = () => {
  const [ token, setToken ] = useStickyState<string|null>(null, 'token')
  const [ user, setUser ] = useState<IUser|null>(null)
  const [ isAuthenticated, setIsAuthenticated ] = useState<boolean>(false)
  const [ isLoading, setIsLoading ] = useState<boolean>(token !== null && token.length > 0)

  const navigate = useNavigate()

  useEffect(() => {
    $Sanctum.csrf()
  }, [])

  useEffect(() => {
    if (token?.length) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      $Auth.me().then(({ data }: any) => {
        setUser(data)
        setIsAuthenticated(true)
      }).catch(() => setToken(null)).finally(() => setIsLoading(false))
    } else {
      setIsLoading(false)
    }
  }, [setToken, token])

  const logout = useCallback(() => {
    setUser(null)
    setToken(null)
    setIsAuthenticated(false)
    localStorage.removeItem('token')
    navigate('/')
  }, [navigate, setToken])

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        token,
        setToken,
        logout,
      }}
    >
      <Outlet />
    </AuthContext.Provider>
  )
}

export default AuthContext
